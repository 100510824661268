export enum DraftBlockType {
  UNSTYLED = 'unstyled',
  ATOMIC = 'atomic',
}

export type DraftInlineStyleType =
  | 'BOLD'
  | 'CODE'
  | 'ITALIC'
  | 'STRIKETHROUGH'
  | 'UNDERLINE';

interface InlineStyleRange {
  style: DraftInlineStyleType;
  offset: number;
  length: number;
}

interface EntityRange {
  key: string;
  offset: number;
  length: number;
}

export interface RawDraftContentBlock {
  key: string;
  type: DraftBlockType | string;
  text: string;
  depth: number;
  inlineStyleRanges: InlineStyleRange[];
  entityRanges: EntityRange[];
  data?: Object;
  children?: RawDraftContentBlock[];
}

type DraftEntityMutability = 'MUTABLE' | 'IMMUTABLE' | 'SEGMENTED';

export interface RawDraftEntity<T> {
  type: string;
  mutability: DraftEntityMutability;
  data: T;
}

export interface RawDraftEntities<T> {
  [key: string]: RawDraftEntity<T>;
}

export interface RawDraftContentState<T> {
  VERSION?: string;
  blocks: RawDraftContentBlock[];
  entityMap: RawDraftEntities<T>;
}
