import React from 'react';
import classname from 'classnames';
import { Button, ButtonPriority } from 'wix-ui-tpa';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './RepositionControls.st.css';
import { classes as commonClasses } from '../SuperHeroImage.st.css';
import { Reposition as RepositionIcon } from '../../Icons/Reposition';

interface RepositionControlsProps {
  loading: boolean;
  onChange: (movementY: number) => void;
  onSave: () => void;
  onCancel: () => void;
}

export const RepositionControls: React.FC<RepositionControlsProps> = (
  props,
) => {
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const [dragging, setDragging] = React.useState(false);
  const [previousTouch, setPreviousTouch] = React.useState<React.Touch | null>(
    null,
  );

  const handlePositionChange = (event: any) => {
    if (!dragging || props.loading) {
      return;
    }

    const currentTouch = event.touches?.[0];

    if (previousTouch && currentTouch) {
      const movementY = currentTouch.pageY - previousTouch.pageY;
      setPreviousTouch(currentTouch);
      props.onChange(movementY);
    } else {
      props.onChange(event.movementY);
    }
  };

  const stopPropagation = (event: any) => {
    event.stopPropagation();
    event.nativeEvent.stopImmediatePropagation();
    setPreviousTouch(null);
  };

  const startRepositioning = (event: any) => {
    if (props.loading) {
      return;
    }
    event.stopPropagation(event);

    setDragging(true);
    if (event && event.touches && event.touches[0]) {
      setPreviousTouch(event.touches[0]);
    }
  };

  const stopRepositioning = () => {
    setDragging(false);
  };

  return (
    <div
      className={st(classes.root, { dragging }, commonClasses.layout)}
      onMouseDown={startRepositioning}
      onTouchStart={startRepositioning}
      onMouseUp={stopRepositioning}
      onTouchEnd={stopRepositioning}
      onMouseMove={handlePositionChange}
      onTouchMove={handlePositionChange}
      onMouseLeave={stopRepositioning}
    >
      <div className={classes.repositionButtonCell}>
        <Button
          upgrade
          className={classname(
            classes.repositionButton,
            commonClasses.greyButton,
          )}
          prefixIcon={<RepositionIcon />}
        >
          {t('groups-web.reposition.drag-cta')}
        </Button>
      </div>
      {!isMobile && (
        <div className={classes.saveCancelCell}>
          <Button
            upgrade
            onClick={props.onCancel}
            onMouseDown={stopPropagation}
            className={classes.cancelButton}
            priority={ButtonPriority.basicSecondary}
          >
            {t('groups-web.reposition.cancel')}
          </Button>
          <Button
            upgrade
            onClick={props.onSave}
            onMouseDown={stopPropagation}
            className={classes.saveButton}
            priority={ButtonPriority.basic}
          >
            {t(
              props.loading
                ? 'groups-web.reposition.saving'
                : 'groups-web.reposition.save',
            )}
          </Button>
        </div>
      )}
    </div>
  );
};

RepositionControls.displayName = 'RepositionControls';
