import { Dimensions, FormFactor, ViewMode } from './types';
import { IHostProps } from '@wix/native-components-infra/dist/src/types/types';

export class OOIHost {
  constructor(private readonly host: IHostProps) {}

  isMobile() {
    return this.host.formFactor === FormFactor.MOBILE;
  }

  isPreview() {
    return this.host.viewMode === ViewMode.PREVIEW;
  }

  getDimensions(): Dimensions {
    const { bottom, height, left, position, right, top, width } =
      this.host.dimensions;
    return {
      bottom: bottom || 0,
      height: height || 0,
      left: left || 0,
      right: right || 0,
      top: top || 0,
      width: width || 0,
      position,
    };
  }
}
