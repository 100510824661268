import React, { FC } from 'react';
import { useBi, WidgetProps } from '@wix/yoshi-flow-editor';
import { GroupWidget, GroupWidgetProps } from './GroupWidget';
import './project.st.css';
import { GROUP_WRAPPER } from './dataHooks';
import { useRedirect } from './hooks/useRedirect';

const Widget: FC<WidgetProps<GroupWidgetProps>> = (props) => {
  const bi = useBi();
  useRedirect({ id: props.group?.groupId!, slug: props.group?.slug! });

  return (
    <div data-hook={GROUP_WRAPPER}>
      <GroupWidget {...props} bi={bi} />
    </div>
  );
};

export default Widget;
