import React from 'react';
import { IUserContext } from './IUserContext';
import { UserContext } from './UserContext';

export const withUser =
  <P extends IUserContext>(WrappedComponent: React.ComponentType<P>) =>
  (props: P & IUserContext) =>
    (
      <UserContext.Consumer>
        {(context) => <WrappedComponent {...context} {...props} />}
      </UserContext.Consumer>
    );
