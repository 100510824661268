import React, { useEffect } from 'react';
import { TextButtonPriority, Dialog } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';
import { BlackAndWhiteTextButton } from '../../BlackAndWhiteTextButton';
import { Header } from '../../Text/Header';
import { Block } from '../../Block';
import { hideBodyOverflow } from '../../../utils/utils';

import { classes } from './MobileRepositionLayout.st.css';

interface MobileRepositionLayoutProps {
  onCancel(): void;
  onSave(): void;
  loading: boolean;
}

export const MobileRepositionLayout: React.FC<MobileRepositionLayoutProps> = (
  props,
) => {
  useEffect(() => {
    hideBodyOverflow(true, classes.overflowHidden);
    return function cleanup() {
      hideBodyOverflow(false, classes.overflowHidden);
    };
  });

  const { t } = useTranslation();
  const { onSave, onCancel, loading } = props;

  return (
    <Dialog
      isOpen={true}
      onClose={onCancel}
      className={classes.root}
      contentClassName={classes.contentWrapper}
      childrenWrapperClassName={classes.dialogContent}
      forceBWTheme={true}
      wiredToSiteColors={false}
    >
      <Block className={classes.header}>
        <BlackAndWhiteTextButton
          priority={TextButtonPriority.secondary}
          onClick={onCancel}
        >
          {t('groups-web.cancel')}
        </BlackAndWhiteTextButton>
        <Header className={classes.title}>
          {t('groups-web.reposition.reposition-cta')}
        </Header>
        <BlackAndWhiteTextButton
          priority={TextButtonPriority.secondary}
          onClick={onSave}
          className={classes.done}
        >
          {t(
            loading
              ? 'groups-web.reposition.saving'
              : 'groups-web.reposition.save',
          )}
        </BlackAndWhiteTextButton>
      </Block>
      <div>{props.children}</div>
    </Dialog>
  );
};
