import React from 'react';
import { ProviderProps, getContextFromProps } from '@wix/social-groups-api';
import { IMembershipQuestions } from './IMembershipQuestions';

const defaultMembershipQuestionsContext: IMembershipQuestions = {
  membershipQuestionsActions: undefined,
  questions: undefined,
};

export const MembershipQuestionsContext =
  React.createContext<IMembershipQuestions>(defaultMembershipQuestionsContext);

export const MembershipQuestionsProvider: React.FC<
  ProviderProps<IMembershipQuestions>
> = ({ children, value }) => {
  const _value = getContextFromProps(value, defaultMembershipQuestionsContext);
  return (
    <MembershipQuestionsContext.Provider value={_value}>
      {children}
    </MembershipQuestionsContext.Provider>
  );
};
MembershipQuestionsProvider.displayName = 'MembershipQuestionsProvider';
