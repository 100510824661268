import React from 'react';
import { TextButton, TextButtonProps } from 'wix-ui-tpa';

import { st, classes } from './BlackAndWhiteTextButton.st.css';

/**
 * We override styles for this TextButton to be Black & White
 */
export const BlackAndWhiteTextButton: React.FC<TextButtonProps> = (props) => (
  <TextButton {...props} className={st(classes.root, props.className)} />
);

BlackAndWhiteTextButton.displayName = 'BlackAndWhiteTextButton';
