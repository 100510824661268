import {
  ApiTypesV1GroupAccessRestriction,
  restrictedByAdmin,
  restrictedByEvents,
  restrictedByPlans,
} from '@wix/social-groups-api';
import React from 'react';
import { Private } from '../../Discussion/Private';
import { RestrictedJoin } from './RestrictedJoin';
import { SomethingWentWrong } from '../../Discussion/SomethingWentWrong';

interface Props {
  accessRestriction: ApiTypesV1GroupAccessRestriction;
}

export const GroupAccess: React.FC<Props> = ({ accessRestriction }) => {
  if (restrictedByAdmin(accessRestriction as any)) {
    return <Private />;
  }

  if (
    restrictedByEvents(accessRestriction as any) ||
    restrictedByPlans(accessRestriction as any)
  ) {
    return <RestrictedJoin />;
  }

  return <SomethingWentWrong isOwner={false} />;
};

GroupAccess.displayName = 'GroupAccess';
