import React from 'react';
import { ToggleSwitchProps } from 'wix-ui-tpa';
import { BlackAndWhiteToggleSwitch } from '../BlackAndWhiteToggleSwitch/BlackAndWhiteToggleSwitch';
import { Block } from './Block';
import { BlockAlign } from './BlockAlign';

interface SwitchBlockProps extends ToggleSwitchProps {
  children?: React.ReactNode;
}

export const SwitchBlock: React.FC<SwitchBlockProps> = (props) => {
  const { children, className, ...rest } = props;
  return (
    <Block
      end={<BlackAndWhiteToggleSwitch {...rest} />}
      align={BlockAlign.center}
      autoContent
      className={className}
    >
      {children}
    </Block>
  );
};

SwitchBlock.displayName = 'SwitchBlock';
