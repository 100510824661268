import React from 'react';
import { ButtonPriority } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Modal } from '../../../../../common/components/Modal/Modal';
import { Button } from '../../../../../common/components/Button/Button';

interface CanNotAddMembersModalProps {
  isOpen: boolean;
  onClose(): void;
}

export const CanNotAddMembersModal: React.FC<CanNotAddMembersModalProps> = ({
  isOpen,
  onClose,
}) => {
  const { t } = useTranslation();
  return (
    <Modal isOpen={isOpen} onClose={onClose}>
      <Modal.Header
        title={t('groups-web.discussion.can-not-add-members-popup.title')}
        subtitle={t('groups-web.discussion.can-not-add-members-popup.subtitle')}
      />
      <Modal.Buttons>
        <Button bw={true} priority={ButtonPriority.primary} onClick={onClose}>
          {t('groups-web.discussion.can-not-add-members-popup.back-to-group')}
        </Button>
      </Modal.Buttons>
    </Modal>
  );
};

CanNotAddMembersModal.displayName = 'CanNotAddMembersModal';
