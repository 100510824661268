import React from 'react';
import classname from 'classnames';
import { Button } from 'wix-ui-tpa';
import { useTranslation, useEnvironment } from '@wix/yoshi-flow-editor';

import { st, classes } from './EditLayout.st.css';
import { classes as commonClasses } from '../SuperHeroImage.st.css';
import { Reposition as RepositionIcon } from '../../Icons/Reposition';
import { PhotoCameraFill as PhotoCameraFillIcon } from '../../Icons/PhotoCameraFill';

interface EditLayoutProps {
  withReposition: boolean;
  onLogoUpload(image: File): void;
  onRepositionClick(): void;
}

export const EditLayout: React.FC<EditLayoutProps> = (props) => {
  const $input = React.useRef<HTMLInputElement>(null);
  const { t } = useTranslation();
  const { isMobile } = useEnvironment();

  const handleFileInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    const [userImage] = Array.from(event.target.files!);

    try {
      props.onLogoUpload(userImage);
    } catch (e) {
      // TODO: handle error (?)
      console.log('EditLayoutComponent.handleFileInputChange', e);
    } finally {
      $input.current!.value = null as any;
    }
  };

  const handleUploadButtonClick = () => {
    $input.current!.click();
  };

  return (
    <div
      className={st(classes.root, { mobile: isMobile }, commonClasses.layout)}
    >
      <div className={classes.buttons}>
        <Button
          className={classname(
            commonClasses.greyButton,
            classes.changePhotoButton,
          )}
          onClick={handleUploadButtonClick}
          prefixIcon={<PhotoCameraFillIcon />}
          upgrade
        >
          <span className={classes.changePhotoButtonLabel}>
            {t('groups-web.reposition.change-photo')}
          </span>
        </Button>
        {props.withReposition ? (
          <Button
            className={classname(
              commonClasses.greyButton,
              classes.repositionButton,
            )}
            onClick={props.onRepositionClick}
            prefixIcon={<RepositionIcon />}
            upgrade
          >
            {t('groups-web.reposition.reposition-cta')}
          </Button>
        ) : null}
      </div>
      <input
        hidden
        data-hook="image-input"
        type="file"
        multiple={false}
        ref={$input}
        onChange={handleFileInputChange}
      />
    </div>
  );
};
