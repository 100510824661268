import { isObject } from '../../utils/utils';
import { RawDraftContentState } from '../types';
import { RawDraftContentBuilder } from './RawDraftContentBuilder';

export class ContentConverter {
  static parseText(contentString: string): string {
    try {
      const converter = new ContentConverter();
      const rawContent = converter.parseDraftJsFromString(contentString);
      return converter.getTextContent(rawContent!);
    } catch (e) {
      return contentString;
    }
  }

  static parseTextFromRaw(rawContent: RawDraftContentState<any>) {
    const converter = new ContentConverter();
    return converter.getTextContent(rawContent);
  }

  getTextContent(rawContent: RawDraftContentState<any>): string {
    const text = rawContent.blocks
      .filter((b) => b.text && b.text.trim())
      .map((block) => block.text.trim())
      .join(' ');
    return text;
  }

  static parseContentString(input: string): RawDraftContentState<any> {
    const converter = new ContentConverter();
    try {
      return converter.parseContentStateString(input);
    } catch (e) {
      return converter.parseContentStateString('');
    }
  }

  /**
   * converts stringified draftjs content to the RawDraftContentState object
   * @param  input
   */
  parseDraftJsFromString<T>(
    input: string,
  ): RawDraftContentState<T> | undefined {
    let draftJs!: RawDraftContentState<T>;
    try {
      draftJs = JSON.parse(input) as RawDraftContentState<T>;
      if (isObject(draftJs)) {
        return draftJs;
      }
    } catch (e) {
      return draftJs;
    }
  }

  /**
   * converts input string that is either stringified draftjs content
   * or plain string to the RawDraftContentState object
   * @param  input
   */
  parseContentStateString<T>(input: string): RawDraftContentState<T> {
    const draftJs =
      this.parseDraftJsFromString<T>(input) || this.convertToRaw<T>(input);
    return draftJs;
  }

  /**
   * converts plain string to the RawDraftContentState object
   * @param  input - plain string
   */
  private convertToRaw<T>(input: string): RawDraftContentState<T> {
    const builder = new RawDraftContentBuilder().withText(input);
    return builder.build();
  }
}
