import React from 'react';
import { TPAComponentsConsumer, TPAComponentsConfig } from 'wix-ui-tpa';

export interface WithTpaComponentsConfigProps extends TPAComponentsConfig {}

export const withTpaComponentsConfig =
  <P extends WithTpaComponentsConfigProps>(
    WrappedComponent: React.ComponentType<P>,
  ) =>
  (props: any) => {
    return (
      <TPAComponentsConsumer>
        {(config) => <WrappedComponent {...config} {...props} />}
      </TPAComponentsConsumer>
    );
  };
withTpaComponentsConfig.displayName = 'withTpaComponentsConfig';
