import React from 'react';
import { AppDataContext, AppDataWithStyles } from './AppData';

export interface WithAppDataProps extends AppDataWithStyles {}

export const withAppData =
  <P extends WithAppDataProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.ComponentType<Omit<P, keyof WithAppDataProps>> =>
  (props) => {
    return (
      <AppDataContext.Consumer>
        {(ctx) => {
          return <WrappedComponent {...ctx} {...(props as P)} />;
        }}
      </AppDataContext.Consumer>
    );
  };
