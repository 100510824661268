import React from 'react';
import { GroupActionsConsumer } from '../GroupActions/GroupActionsContext';
import { InviteMembersProps } from './InviteMembersProps';

export const withInviteMembers =
  <P extends InviteMembersProps>(
    WrappedComponent: React.ComponentType<P>,
  ): React.FC<P> =>
  (props) =>
    (
      <GroupActionsConsumer>
        {(actions) => (
          <WrappedComponent
            {...props}
            inviteMembersByEmail={actions.inviteMembersByEmail}
          />
        )}
      </GroupActionsConsumer>
    );
