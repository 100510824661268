import React from 'react';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { LetterIcon } from '../../icons/LetterIcon';
import { MemberCard } from '../../MemberCard/MemberCard';

import { classes } from './AddMembersModal.st.css';

export interface InviteProps {
  email: string;
  done: boolean;
  invite(): void;
}

export const Invites: React.FC<InviteProps> = (props) => {
  const { email, done, invite } = props;
  const { t } = useTranslation();

  return (
    <MemberCard
      name={email}
      image={<LetterIcon className={classes.inviteIcon} />}
      done={done}
      onActionClick={invite}
      actionLabel={t('groups-web.invite')}
      actionDoneLabel={t('groups-web.invited')}
    />
  );
};

Invites.displayName = 'Invites';
