import React from 'react';
import { Dialog } from 'wix-ui-tpa';
import { ModalProps } from '../Modal';
import { st, classes } from './SideDrawer.st.css';
import { hideBodyOverflow } from '../../utils/utils';
import { useEnvironment } from '@wix/yoshi-flow-editor';

interface SideDrawerProps extends ModalProps {
  children?: React.ReactNode;
}

export const SideDrawer: React.FC<SideDrawerProps> = (props) => {
  hideBodyOverflow(props.isOpen!!, classes.overflowHidden);
  const { skin, ...rest } = props;
  const { isMobile } = useEnvironment();
  return (
    <Dialog
      {...rest}
      forceBWTheme={true}
      wiredToSiteColors={false}
      childrenWrapperClassName={classes.dialogContent}
      contentClassName={classes.contentWrapper}
      className={st(classes.root, { mobile: isMobile }, props.className)}
    />
  );
};
SideDrawer.displayName = 'SideDrawer';
