import React from 'react';
import { ProviderProps, getContextFromProps } from '@wix/social-groups-api';
import { IJoinGroupRequests } from '../../controllers/joinGroupRequests/IJoinGroupRequests';

const defaultJoinGroupRequestsContext: IJoinGroupRequests = {
  joinGroupRequestsActions: {} as any,
  joinGroupRequestRequests: null,
  joinGroupRequestsResponse: null,
};
export const JoinGroupRequestsContext = React.createContext<IJoinGroupRequests>(
  defaultJoinGroupRequestsContext,
);

export const JoinGroupRequestsContextProvider: React.FC<
  ProviderProps<IJoinGroupRequests>
> = ({ children, value }) => {
  const _value = getContextFromProps(value, defaultJoinGroupRequestsContext);
  return (
    <JoinGroupRequestsContext.Provider value={_value}>
      {children}
    </JoinGroupRequestsContext.Provider>
  );
};

JoinGroupRequestsContextProvider.displayName =
  'JoinGroupRequestsContextProvider';
