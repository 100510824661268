import React from 'react';
import { HeroImage, HeroImageProps } from 'wix-ui-tpa';
import { FileUploadResponse } from '@wix/social-groups-api';

import { EditLayout } from './EditLayout/EditLayout';
import { RepositionLayout } from './Reposition/RepositionLayout';
import { compose } from '../../utils/compose';
import {
  withUploadAction,
  WithUploadProps,
} from '../../../components/Group/contexts/GroupActions/UploadAction';
import {
  withTpaComponentsConfig,
  WithTpaComponentsConfigProps,
} from '../../../components/Group/contexts/TPAComponent/withTpaComponentsConfig';
import { st, classes } from './SuperHeroImage.st.css';

export interface SuperHeroImageChanges {
  imageResponse?: FileUploadResponse;
  focalPointY: number;
}

interface SuperHeroImageProps extends HeroImageProps {
  width: number;
  height: number;
  initialSourceHeight: number;
  initialFocalPointY: number;
  showEditControls: boolean;
  onSave(changes: SuperHeroImageChanges): void;
  onSaved?(): void;
}

interface State {
  inRepositionMode: boolean;
  focalPointY: number;
  loading: boolean;
  localImageUrl: string;
  sourceHeight: number;
}

type Props = SuperHeroImageProps &
  WithUploadProps &
  WithTpaComponentsConfigProps;

const DEFAULT_FOCAL_POINT_Y = 50;
const DEFAULT_FOCAL_POINT_X = 0;

class SuperHeroImageComponent extends React.Component<Props, State> {
  private fileToUpload!: File | null;
  private rootRef = React.createRef<HTMLDivElement>();

  state = {
    loading: false,
    inRepositionMode: false,
    focalPointY:
      this.props.initialFocalPointY == null
        ? DEFAULT_FOCAL_POINT_Y
        : this.props.initialFocalPointY,
    localImageUrl: '',
    sourceHeight: this.props.initialSourceHeight,
  };

  componentDidUpdate(prevProps: Readonly<Props>): void {
    const { focalPointY, loading } = this.state;
    const { uploadedRegistry } = this.props;

    // image uploading response
    if (prevProps.uploadedRegistry !== uploadedRegistry && this.fileToUpload) {
      const uploadFile = uploadedRegistry.find(
        (file) => file.filename === this.fileToUpload!.name,
      );
      if (uploadFile) {
        this.fileToUpload = null;
        this.props.onSave({
          imageResponse: uploadFile.uploadResponse[0],
          focalPointY: this.state.focalPointY,
        });
      }
    }

    // updating logo position response
    if (loading && this.props.initialFocalPointY === focalPointY) {
      this.turnOffRepositionMode();
      this.setState({
        loading: false,
      });
      this.props.onSaved && this.props.onSaved();
    }
  }

  render() {
    const { showEditControls, className, width, height } = this.props;
    const { inRepositionMode } = this.state;

    return (
      <div
        ref={this.rootRef}
        className={st(classes.root, {}, className)}
        style={{
          height: `${height}px`,
        }}
        data-hook="super-hero-image-root"
      >
        {this.renderImage()}
        {showEditControls && !inRepositionMode && (
          <EditLayout
            withReposition={this.hasImage()}
            onRepositionClick={this.turnOnRepositionMode}
            onLogoUpload={this.handleLogoChanged}
          />
        )}
        {inRepositionMode && (
          <RepositionLayout
            height={height}
            width={width}
            loading={this.state.loading}
            onSave={this.handleSave}
            onPositionChange={this.handlePositionChange}
            onCancel={this.handleCancel}
            renderImage={this.renderImage}
            rootRef={this.rootRef}
          />
        )}
      </div>
    );
  }

  private hasImage = () => !!(this.state.localImageUrl || this.props.src);

  private renderImage = () => {
    const { localImageUrl, focalPointY } = this.state;

    if (localImageUrl) {
      return (
        <img
          className={classes.newImage}
          src={this.state.localImageUrl}
          alt=""
          style={{
            objectPosition: `${DEFAULT_FOCAL_POINT_X}% ${focalPointY}%`,
          }}
        />
      );
    }

    if (this.props.src) {
      return (
        <HeroImage
          {...this.props}
          focalPoint={{
            x: DEFAULT_FOCAL_POINT_X,
            y: focalPointY,
          }}
        />
      );
    }

    return null;
  };

  private turnOnRepositionMode = () => this.setRepositionMode(true);
  private turnOffRepositionMode = () => this.setRepositionMode(false);
  private setRepositionMode = (inRepositionMode: boolean) => {
    this.setState({
      inRepositionMode,
    });
  };

  private handleLogoChanged = (image: File) => {
    const { width } = this.props;
    if (this.state.localImageUrl) {
      URL.revokeObjectURL(this.state.localImageUrl);
    }

    this.fileToUpload = image;
    const localImageUrl = URL.createObjectURL(image);
    const img = new Image();

    img.onload = () => {
      this.setState({
        sourceHeight: img.height * (width / img.width),
        localImageUrl,
        focalPointY: DEFAULT_FOCAL_POINT_Y,
      });
      this.turnOnRepositionMode();
    };

    img.src = localImageUrl;
  };

  private handlePositionChange = (movementY: number) => {
    const { focalPointY, sourceHeight } = this.state;
    this.setState({
      focalPointY: minmax(
        0,
        100,
        round(focalPointY - (movementY / sourceHeight) * 100),
      ),
    });
  };

  private handleCancel = () => {
    this.turnOffRepositionMode();
    this.setState({
      localImageUrl: '',
      focalPointY: this.props.initialFocalPointY,
      sourceHeight: this.props.initialSourceHeight,
    });
  };

  private handleSave = () => {
    const { onSave, uploadFiles } = this.props;
    const { focalPointY, loading } = this.state;
    if (loading) {
      return;
    }

    if (this.fileToUpload) {
      uploadFiles(this.fileToUpload.name, this.fileToUpload);
      this.setState({ loading: true });
    } else {
      if (onSave) {
        onSave({ focalPointY });
        this.setState({
          loading: true,
        });
      }
    }
  };
}

const minmax = (
  minValue: number,
  maxValue: number,
  currentValue: number,
): number => Math.min(maxValue, Math.max(minValue, currentValue));

const round = (value: number): number => Math.round(value * 100) / 100;

const enhance = compose(withUploadAction, withTpaComponentsConfig);
export const SuperHeroImage = enhance(
  SuperHeroImageComponent,
) as React.ComponentType<SuperHeroImageProps>;

SuperHeroImage.displayName = 'SuperHeroImage';
