import React from 'react';
import { Text, Checkbox } from 'wix-ui-tpa';
import { useTranslation } from '@wix/yoshi-flow-editor';

import { Block, BlockAlign } from '../../../../../common/components/Block';
import { classes, st } from './AddMembersModal.st.css';
import { Button } from '../../../../../common/components/Button';
import { Loader } from '../../../../../common/components/Loader';
import { ADD_MEMBERS_HOOK } from './AddMembersModal';
import { PluralText } from 'common/components/PluralText';

export interface AddMembersBarProps {
  selectedCount: number;
  totalCount: number;
  updating: boolean;
  selectAll: boolean;
  showCheckbox: boolean;
  onSelectAll(): void;
  onAdd(): void;
}

export const AddMembersBar: React.FC<AddMembersBarProps> = (props) => {
  const {
    selectedCount,
    totalCount,
    updating,
    selectAll,
    onSelectAll,
    onAdd,
    showCheckbox,
  } = props;
  const { t } = useTranslation();

  if (!totalCount) {
    return null;
  }

  return (
    <Block
      justify={BlockAlign.start}
      align={BlockAlign.center}
      className={st(classes.selectAll, {
        withButton: !!selectedCount,
        notEmptySearch: !showCheckbox,
      })}
    >
      {showCheckbox ? (
        <Checkbox
          className={classes.selectAllCheckbox}
          checked={selectAll}
          indeterminate={!selectAll && !!selectedCount}
          onChange={onSelectAll}
        />
      ) : null}
      <Text className={classes.selectedCount}>
        {!showCheckbox || !!selectedCount ? (
          <PluralText
            translationKey="group-web.add.members.selected"
            count={selectedCount}
          />
        ) : (
          t('group-web.add.members.select-all', {
            count: totalCount,
          })
        )}
      </Text>
      {selectedCount ? (
        <Button
          disabled={updating}
          prefixIcon={updating ? <Loader /> : undefined}
          onClick={onAdd}
          data-hook={ADD_MEMBERS_HOOK}
          bw={true}
        >
          {t('groups-web.add')}
        </Button>
      ) : null}
    </Block>
  );
};

AddMembersBar.displayName = 'AddMembersBar';
