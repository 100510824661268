import React from 'react';
import { ProviderProps, getContextFromProps } from '@wix/social-groups-api';
import { IMemberFollow } from './IMemberFollow';

const defaultMemberFollowContext: IMemberFollow = {
  followActions: undefined,
  following: undefined,
};

export const MemberFollowContext = React.createContext<IMemberFollow>(
  defaultMemberFollowContext,
);

export const MemberFollowProvider: React.FC<ProviderProps<IMemberFollow>> = ({
  children,
  value,
}) => {
  const _value = getContextFromProps(value, defaultMemberFollowContext);
  return (
    <MemberFollowContext.Provider value={_value}>
      {children}
    </MemberFollowContext.Provider>
  );
};
MemberFollowProvider.displayName = 'MemberFollowProvider';
