import React from 'react';
import { UserContextProvider } from '../../../common/context/user/UserContext';
import { GroupWidgetProps } from '../Widget/GroupWidget';
import { ProviderProps } from '@wix/social-groups-api';
import { GroupV2ContextProvider } from './Group/GroupV2Context';
import { JoinGroupRequestsContextProvider } from './JoinGroupRequests/JoinGroupRequestsContext';
import { MemberFollowProvider } from './MemberFollow/MemberFollow';
import { MembershipQuestionsProvider } from './MembershipQuestions/MembershipQuestions';

export const GroupV2Providers: React.FC<ProviderProps<GroupWidgetProps>> = ({
  children,
  value,
}) => {
  return (
    <>
      <GroupV2ContextProvider value={value}>
        <UserContextProvider value={value}>
          <JoinGroupRequestsContextProvider value={value}>
            <MemberFollowProvider value={value}>
              <MembershipQuestionsProvider value={value}>
                {children}
              </MembershipQuestionsProvider>
            </MemberFollowProvider>
          </JoinGroupRequestsContextProvider>
        </UserContextProvider>
      </GroupV2ContextProvider>
    </>
  );
};

GroupV2Providers.displayName = 'GroupV2Providers';
