import React from 'react';

export interface ClickOutsideProps {
  rootRef: React.RefObject<HTMLElement>;
  onClickOutside: Function;
}

export class ClickOutside extends React.PureComponent<ClickOutsideProps> {
  private shouldListenOutsideClick: boolean = false;

  componentDidMount() {
    document.addEventListener('mousedown', this.startListenOutsideClick);
    document.addEventListener('mouseup', this.onClickOutside);
  }

  componentWillUnmount() {
    document.removeEventListener('mousedown', this.startListenOutsideClick);
    document.removeEventListener('mouseup', this.onClickOutside);
  }

  render() {
    return this.props.children;
  }

  private startListenOutsideClick = () => {
    this.shouldListenOutsideClick = true;
  };

  private isInsideClick = (event: any) => {
    const { rootRef } = this.props;
    let target: HTMLElement | null = event.target;
    while (target) {
      // Same node
      if (rootRef.current === target) {
        return true;
      }

      target = target.parentElement;
    }
  };

  private onClickOutside = (event: any) => {
    const { onClickOutside } = this.props;
    if (this.shouldListenOutsideClick) {
      if (!this.isInsideClick(event)) {
        onClickOutside(event);
      }
      this.shouldListenOutsideClick = false;
    }
  };
}
