import React from 'react';
import { Divider } from 'wix-ui-tpa';

import { st, classes } from './Divider.st.css';

export const StyledDivider: React.FC<{
  className?: string;
}> = (props) => {
  return <Divider className={st(classes.root, {}, props.className)} />;
};

StyledDivider.displayName = 'StyledDivider';
