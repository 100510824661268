export enum ActionType {
  WITHDRAW_JOIN_REQUEST = 'cancel join request',
  LEAVE_GROUP = 'leave group',
  JOIN_DIALOG = 'join dialog',
  PRIVACY_DIALOG = 'change profile privacy',
  QUESTIONS = 'questions',
  REGISTER_EVENTS = 'register_events',
  WRITE_POST = 'write_post',
  CHECK_GROUP_MEMBERSHIP = 'check_group_membership',
  CHANGE_GROUP_MEMBERSHIP = 'change group membership',
}
