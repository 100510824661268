import React from 'react';
import { ImageLoadingBehaviorOptions, ImageResizeOptions } from 'wix-ui-tpa';
import { ApiTypesV1GroupResponse } from '@wix/social-groups-api';

import { CoverImageLayout } from '../../Settings/settingsConstants';
import { classes } from './Header.st.css';
import {
  SuperHeroImage,
  SuperHeroImageChanges,
} from '../../../../common/components/SuperHeroImage/SuperHeroImage';
import { useGroupSettings } from '../hooks/useGroupSettings';
import {
  getRepositioningLogo,
  RepositioningLogo,
} from './getRepositioningLogo';
import { useGroupWrapper } from '../../../../common/hooks/useGroupWrapper';

interface Props {
  group: ApiTypesV1GroupResponse;
  onChange(changes: SuperHeroImageChanges): void;
  onSaved(): void;
}
const GROUP_IMAGE = 'group-image';

export const Cover: React.FC<Props> = ({ group, onChange, onSaved }) => {
  const { coverImageLayout, targetWidth, targetHeight } = useGroupSettings();
  const wrappedGroup = useGroupWrapper(group);

  let repositioningLogo: RepositioningLogo = {};
  if (wrappedGroup.logo) {
    repositioningLogo = getRepositioningLogo(wrappedGroup, {
      targetWidth,
      targetHeight,
    });
  }
  const isLarge = coverImageLayout === CoverImageLayout.large;
  const { logoUrl, height, focalPointY } = repositioningLogo;
  const showEditControls = wrappedGroup.admin && isLarge;

  return (
    <SuperHeroImage
      src={logoUrl}
      initialSourceHeight={height!}
      initialFocalPointY={focalPointY!}
      onSave={onChange}
      onSaved={onSaved}
      showEditControls={showEditControls}
      fluid={isLarge}
      className={classes.largeImage}
      width={targetWidth}
      height={targetHeight}
      loadingBehavior={ImageLoadingBehaviorOptions.blur}
      resize={ImageResizeOptions.cover}
      data-hook={GROUP_IMAGE}
    />
  );
};

Cover.displayName = 'Cover';
